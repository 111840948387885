<template>
  <edit-role-dialog
    :permissions="permissions"
    :users="users"
    :role="role"
    v-if="users && permissions && role"
    @save="$emit('save')"
  />
</template>

<script>
import { queries } from "@/gql";
import EditRoleDialog from "./EditRoleDialog";

const { permissions, users } = queries;

export default {
  components: { EditRoleDialog },
  apollo: { permissions, users },
  props: ["role"],
};
</script>
