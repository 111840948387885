<template>
  <create-role-dialog
    @create="handleCreate"
    v-if="permissions && users"
    :permissions="permissions"
    :users="users"
  />
</template>

<script>
import { queries } from "@/gql";
import CreateRoleDialog from "./CreateRoleDialog";

const { permissions, users } = queries;
export default {
  components: { CreateRoleDialog },
  apollo: { permissions, users },
  methods: {
    handleCreate() {
      console.log("wrapper create");
      this.$emit("create");
    },
  },
};
</script>
