<template>
  <v-row class="ma-4" v-if="groups">
    <page-header />
    <v-col cols="12">
      <v-card tile>
        <table-header @refresh="$apollo.queries.groups.refetch()" title="Roles">
          <create-role-dialog-wrapper
            @create="$apollo.queries.groups.refetch()"
          />
          <default-button
            @click="$apollo.queries.groups.refetch()"
            icon="fa-sync"
          >
            Refresh tables
          </default-button>
        </table-header>

        <v-card-text>
          <role-table
            :roles="groups"
            @update="$apollo.queries.groups.refetch()"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import RoleTable from "@/components/tables/RoleTable";
import { queries } from "@/gql";
import PageHeader from "@/components/widgets/PageHeader";
import TableHeader from "@/components/widgets/TableHeader";
import DefaultButton from "@/components/widgets/DefaultButton";
import CreateRoleDialogWrapper from "../components/dialogs/CreateRoleDialogWrapper";
const { groups } = queries;

export default {
  apollo: { groups },
  components: {
    CreateRoleDialogWrapper,
    DefaultButton,
    TableHeader,
    PageHeader,
    RoleTable,
  },
};
</script>
