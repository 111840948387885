<template>
  <div>
    <v-snackbar v-model="snackbar" color="success" top right>
      <span class="white--text">Copied to clipboard!</span>
    </v-snackbar>
    <v-data-table
      dense
      :headers="headers"
      :items="roles"
      item-key="name"
      class="elevation-0"
    >
      <template v-slot:item.name="{ item }">
        <div class="my-2">
          <h4>{{ item.name }}</h4>
          <edit-role-dialog-wrapper :role="item" @save="$emit('update')" />
        </div>
      </template>
      <template v-slot:item.permissions="{ item }">
        <template v-for="(perm, i) in item.permissions">
          <v-chip small label v-if="i < 2" :key="perm.key" class="mx-1">
            {{ perm.name }}
          </v-chip>
        </template>
        <span class="grey--text" v-if="item.permissions.length > 2">
          + {{ item.permissions.length - 2 }} more</span
        >
      </template>
      <template v-slot:item.users="{ item }">
        <template v-for="(user, i) in item.users">
          <v-chip small label v-if="i < 2" :key="user.username" class="mx-1">
            {{ user.username }}
          </v-chip>
        </template>
        <span class="grey--text" v-if="item.users.length > 2">
          + {{ item.users.length - 2 }} more</span
        >
      </template>
      <template v-slot:item.system="{ item }">
        <v-icon v-if="item.system" color="success" small>fa-check</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <delete-role-dialog
          :role="item"
          @delete="$emit('update')"
          :disabled="item.system"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { fileSize, niceDate } from "@/filters";
import DeleteRoleDialog from "@/components/dialogs/DeleteRoleDialog";
import EditRoleDialogWrapper from "../dialogs/EditRoleDialogWrapper";

export default {
  props: ["roles"],
  components: { EditRoleDialogWrapper, DeleteRoleDialog },
  filters: { fileSize, niceDate },
  methods: {
    clipboardSuccessHandler() {
      this.snackbar = true;
    },
  },
  data: () => ({
    snackbar: false,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Permissions",
        value: "permissions",
      },
      {
        text: "Members",
        value: "users",
      },
      {
        text: "System",
        value: "system",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
  }),
};
</script>
