<template>
  <Dialog
    v-model="dialog"
    btn-text="Create new role"
    btn-icon="fa-plus"
    btn-color="success"
    title="Create new role"
  >
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-card-text class="mt-3">
      <v-form v-model="valid">
        <role-form v-model="form" :permissions="permissions" :users="users" />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button
        :loading="loading"
        :disabled="!valid"
        @click="createRole"
        data-test="save-button"
        icon="fa-plus"
      >
        Save changes
      </default-button>
    </v-card-actions>
  </Dialog>
</template>

<script>
import { mutations } from "@/gql";
import Dialog from "@/components/dialogs/Dialog";
import RoleForm from "@/components/forms/RoleForm";
import DefaultButton from "@/components/widgets/DefaultButton";

const { createGroup: mutation } = mutations;
export default {
  components: { Dialog, RoleForm, DefaultButton },
  props: ["permissions", "users"],
  data: () => ({
    valid: null,
    dialog: false,
    loading: false,
    form: {
      name: null,
      permissions: [],
      users: [],
    },
    required: [(v) => (v && v.length > 0) || "This field is required"],
    error: false,
    errorMessage: null,
  }),
  methods: {
    createRole() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            name: this.form.name,
            permissions: this.form.permissions,
            users: this.form.users,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message;
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.$emit("create");
            this.loading = false;
            this.dialog = false;
          }
        });
    },
  },
};
</script>
