var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"subtitle-1 mt-2",attrs:{"cols":"2"}},[_vm._v("Role name")]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"dense":"","id":"role-name","outlined":"","disabled":_vm.role && _vm.role.system,"persistent-hint":_vm.role && _vm.role.system,"hint":_vm.role && _vm.role.system && 'You cannot edit the name of a system role',"rules":_vm.required},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{staticClass:"subtitle-1 mt-2",attrs:{"cols":"2"}},[_vm._v("Permissions")]),_c('v-col',{attrs:{"cols":"10"}},[(_vm.role && _vm.role.system)?_c('v-list',[_vm._l((_vm.selectedPermissions),function(permission,i){return [_c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(permission.name))])],1)],1),_c('v-divider',{key:("divider-" + i)})]})],2):_c('v-autocomplete',{attrs:{"multiple":"","dense":"","outlined":"","data-test":"role-permissions","item-text":"name","item-value":"id","items":_vm.permissions,"clearable":"","rules":_vm.required},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 3)?_c('v-chip',{attrs:{"small":"","label":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.form.permissions.length - 3)+" more) ")]):_vm._e()]}}]),model:{value:(_vm.form.permissions),callback:function ($$v) {_vm.$set(_vm.form, "permissions", $$v)},expression:"form.permissions"}})],1),_c('v-col',{staticClass:"subtitle-1 mt-2",attrs:{"cols":"2"}},[_vm._v("Users/tokens")]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-autocomplete',{attrs:{"multiple":"","dense":"","outlined":"","id":"role-users","item-text":"name","item-value":"username","items":_vm.users,"clearable":"","rules":_vm.required},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 3)?_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.username)),(item.serviceAccount)?_c('span',[_vm._v(" (Access token)")]):_vm._e()]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.form.permissions.length - 3)+" more) ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.username))]),(item.serviceAccount)?_c('v-list-item-subtitle',[_vm._v("Access token")]):_c('v-list-item-subtitle',[_vm._v("User account")])],1)]}}]),model:{value:(_vm.form.users),callback:function ($$v) {_vm.$set(_vm.form, "users", $$v)},expression:"form.users"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }