var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"success","top":"","right":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticClass:"white--text"},[_vm._v("Copied to clipboard!")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.roles,"item-key":"name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('h4',[_vm._v(_vm._s(item.name))]),_c('edit-role-dialog-wrapper',{attrs:{"role":item},on:{"save":function($event){return _vm.$emit('update')}}})],1)]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_vm._l((item.permissions),function(perm,i){return [(i < 2)?_c('v-chip',{key:perm.key,staticClass:"mx-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(perm.name)+" ")]):_vm._e()]}),(item.permissions.length > 2)?_c('span',{staticClass:"grey--text"},[_vm._v(" + "+_vm._s(item.permissions.length - 2)+" more")]):_vm._e()]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_vm._l((item.users),function(user,i){return [(i < 2)?_c('v-chip',{key:user.username,staticClass:"mx-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(user.username)+" ")]):_vm._e()]}),(item.users.length > 2)?_c('span',{staticClass:"grey--text"},[_vm._v(" + "+_vm._s(item.users.length - 2)+" more")]):_vm._e()]}},{key:"item.system",fn:function(ref){
var item = ref.item;
return [(item.system)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("fa-check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('delete-role-dialog',{attrs:{"role":item,"disabled":item.system},on:{"delete":function($event){return _vm.$emit('update')}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }