<template>
  <Dialog
    btn-text="Delete role"
    :disabled="disabled"
    btn-color="error"
    btn-icon="fa-trash"
    v-model="dialog"
    title="Really delete role?"
  >
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-card-text>
      You are about to delete the role, {{ role.name }}. This action cannot be
      undone. Are you sure you want to proceed?
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button
        :loading="loading"
        @click="deleteRole"
        icon="fa-trash"
        color="error"
      >
        Delete role
      </default-button>
    </v-card-actions>
  </Dialog>
</template>
<script>
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";
import { mutations } from "@/gql";

const { deleteGroup: mutation } = mutations;

export default {
  props: ["disabled", "role"],
  components: { DefaultButton, Dialog },
  data: () => ({
    loading: false,
    error: false,
    errorMessage: null,
    dialog: false,
  }),
  methods: {
    deleteRole() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            pk: this.role.id,
          },
        })
        .then((result) => {
          if (result) {
            this.$emit("delete");
            this.loading = false;
            this.dialog = false;
          }
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        });
    },
  },
};
</script>
