<template>
  <v-row>
    <v-col cols="2" class="subtitle-1 mt-2">Role name</v-col>
    <v-col cols="10">
      <v-text-field
        v-model="form.name"
        dense
        id="role-name"
        outlined
        :disabled="role && role.system"
        :persistent-hint="role && role.system"
        :hint="
          role && role.system && 'You cannot edit the name of a system role'
        "
        :rules="required"
      />
    </v-col>

    <v-col cols="2" class="subtitle-1 mt-2">Permissions</v-col>
    <v-col cols="10">
      <v-list v-if="role && role.system">
        <template v-for="(permission, i) in selectedPermissions">
          <v-list-item :key="i">
            <v-list-item-content>
              <v-list-item-title>{{ permission.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`divider-${i}`" />
        </template>
      </v-list>
      <v-autocomplete
        v-else
        multiple
        v-model="form.permissions"
        dense
        outlined
        data-test="role-permissions"
        item-text="name"
        item-value="id"
        :items="permissions"
        clearable
        :rules="required"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small label v-if="index < 3">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 4" class="grey--text text-caption">
            (+{{ form.permissions.length - 3 }} more)
          </span>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col cols="2" class="subtitle-1 mt-2">Users/tokens</v-col>
    <v-col cols="10">
      <v-autocomplete
        multiple
        v-model="form.users"
        dense
        outlined
        id="role-users"
        item-text="name"
        item-value="username"
        :items="users"
        clearable
        :rules="required"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small label v-if="index < 3">
            {{ item.username
            }}<span v-if="item.serviceAccount">&nbsp;(Access token)</span>
          </v-chip>
          <span v-if="index === 4" class="grey--text text-caption">
            (+{{ form.permissions.length - 3 }} more)
          </span>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.username }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.serviceAccount"
              >Access token</v-list-item-subtitle
            >
            <v-list-item-subtitle v-else>User account</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["value", "role", "permissions", "users"],
  data: () => ({
    required: [(v) => !!v || "This field is required"],
  }),
  computed: {
    selectedPermissions() {
      if (this.permissions) {
        return this.form.permissions.map((permissionId) =>
          this.permissions.find((perm) => perm.id === permissionId),
        );
      }
      return [];
    },
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
