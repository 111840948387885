<template>
  <Dialog v-model="dialog" :title="`Edit role: ${role.name}`">
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <a v-on="on" data-test="edit-url">Edit</a>
    </template>
    <v-card-text class="mt-3">
      <v-alert
        v-if="role.system"
        type="info"
        dense
        text
        class="mb-10"
        data-test="system-role-warning"
      >
        This is a default system role - you cannot change its name or
        permissions, but you can edit the users assigned to it
      </v-alert>
      <v-form v-model="valid">
        <role-form
          v-model="form"
          :role="role"
          :permissions="permissions"
          :users="users"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        :loading="loading"
        :disabled="!valid"
        color="primary"
        @click="editRole"
      >
        Save changes
      </v-btn>
    </v-card-actions>
  </Dialog>
</template>

<script>
import { mutations } from "@/gql";
import Dialog from "@/components/dialogs/Dialog";
import RoleForm from "@/components/forms/RoleForm";

const { editGroup: mutation } = mutations;
export default {
  components: { Dialog, RoleForm },
  props: ["role", "permissions", "users"],
  mounted() {
    this.form.name = this.role.name;
    this.form.permissions = this.role.permissions.map((perm) => perm.id);
    this.form.users = this.role.users.map((perm) => perm.username);
  },
  data: () => ({
    valid: null,
    dialog: false,
    loading: false,
    form: {
      name: null,
      permissions: [],
      users: [],
    },
    required: [(v) => (v && v.length > 0) || "This field is required"],
    error: false,
    errorMessage: null,
  }),
  methods: {
    editRole() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            pk: this.role.id,
            name: this.form.name,
            permissions: this.form.permissions,
            users: this.form.users,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.$emit("save");
            this.loading = false;
            this.dialog = false;
          }
        });
    },
  },
};
</script>
